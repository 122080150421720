// @ts-nocheck
import {Column} from 'react-table'
import {EmployeeInfoCell, InfoCell} from './EmployeeInfoCell'
import {EmployeeTwoStepsCell} from './EmployeeTwoStepsCell'
import {EmployeeActionsCell} from './EmployeeActionsCell'
import {EmployeeCustomHeader} from './EmployeeCustomHeader'
import {Employee} from '../../core/_models'
import {DateTimeFormat} from '../../../../../../../_metronic/helpers/components/dateFormat'

const EmployeeColumns: ReadonlyArray<Column<Employee>> = [
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='S.No' className='min-w-75px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Product' className='min-w-175px' />
    ),
    accessor: 'ProductMaster.name',
    Cell: ({...props}) => <InfoCell data='buy' user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'user.name',
    Cell: ({...props}) => <InfoCell data='user' user={props.data[props.row.index]?.user} />,
  },

  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Quantity' className='min-w-125px' />
    ),
    accessor: 'qty',
    Cell: ({row}) => {
      const data = row.original
      const meaurement={
        1 : "Kilograms",
        2 : "Tons",
        3 : "Pieces",
        4 : "Liters",
      }
      return (
        <div className='d-flex justify-content-start flex-column'>
          <div>{data?.qty} {data?.measurement?.name}</div>
          <div className='text-muted fw-semibold text-muted d-block fs-7'>Rs {data?.price_per_unit} per {data?.measurement?.name}</div>
        </div>
      )
    },
  },

  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Location' className='min-w-125px' />
    ),
    accessor: 'pincode.region',
        Cell: ({row}) => {
      const data = row.original
      return (
        <div className='d-flex justify-content-start flex-column'>
          <div>{data?.pincode.region}</div>
          <div className='text-muted fw-semibold text-muted d-block fs-7'>{data?.pincode.state}</div>
        </div>
      )
    },
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status',
    Cell: ({...props}) => {
      // Status to Badge Color Mapping
      const statusMappings = {
        0: {label: 'Deleted', className: 'badge bg-danger'},
        1: {label: 'Requested', className: 'badge bg-warning'},
        2: {label: 'Completed', className: 'badge bg-success'},
        3: {label: 'Expired', className: 'badge bg-primary'},
        4: {label: 'Closed', className: 'badge bg-info'},
        // Add more mappings as needed
      }
      const statusValue = props.data[props.row.index].status
      const status = statusMappings[statusValue] || {label: 'Unknown', className: 'badge bg-light'}

      return <span className={status.className}>{status.label}</span>
    },
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Requested Date' className='min-w-125px' />
    ),
    accessor: 'created_date',
    Cell: ({row}) => {
      const data = row.original
      return <DateTimeFormat date={data.created_date} />
    },
  },

  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Expired Date' className='min-w-125px' />
    ),
    accessor: 'valid_date',
    Cell: ({row}) => {
      const data = row.original
      return <DateTimeFormat date={data.valid_date} />
    },
  },
]

export {EmployeeColumns}
