/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {dateFormat, timeFormat} from '../../../../../auth'
import {Link} from 'react-router-dom'
import Avatar from 'react-avatar'

function timesFormat(date: any) {
  return new Date(date).toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'Asia/Kolkata',
  })
}

export const ProductStartInfoCell: FC<any> = ({product}) => {
  const startDate = product?.user_plan_detail?.startDate
  if (!startDate) {
    return <div className='symbol-label'>-</div>
  }
  return (
    <div className='d-flex justify-content-start flex-column'>
      <div>{dateFormat(startDate)}</div>
      <div className='text-muted fw-semibold text-muted d-block fs-7'>{timeFormat(startDate)}</div>
    </div>
  )
}

export const ProductEndInfoCell: FC<any> = ({product}) => {
  const endDate = product?.user_plan_detail?.endDate
  if (!endDate) {
    return <div className='symbol-label'>-</div>
  }
  return (
    <div className='d-flex justify-content-start flex-column'>
      <div>{dateFormat(endDate)}</div>
      <div className='text-muted fw-semibold text-muted d-block fs-7'>{timeFormat(endDate)}</div>
    </div>
  )
}
export const SubscriptionDateInfoCell: FC<any> = ({product}) => {
  const endDate = product?.created_at
  return (
    <div className='d-flex justify-content-start flex-column'>
      <div>{dateFormat(endDate)}</div>
      <div className='text-muted fw-semibold text-muted d-block fs-7'>{timesFormat(endDate)}</div>
    </div>
  )
}

export const ProductStatusCell: FC<any> = ({product}) => {
  const status = product?.user_plan_detail?.isExpired

  if (status === '' || status === null || status === undefined) {
    return <div className='symbol-label'>-</div>
  }

  return (
    <div className='d-flex align-items-center'>
      <p>
        {status ? (
          <span className='badge badge-danger'>Expired</span>
        ) : (
          <span className='badge badge-success'>Active</span>
        )}
      </p>
    </div>
  )
}

export const InfoCell: FC<any> = ({user}) => {
  const badgeBorderClasses: any = {
    Trader: 'text-info',
    Buyer: 'text-success',
    Seller: 'text-warning',
    Farmer: 'text-primary',
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-2'>
        <Link
          to={`/users/view/${user?.id}`}
          className='text-gray-900 fw-bold text-hover-primary fs-6'
        >
          {user?.profile_img ? (
            <img
              src={user.profile_img}
              alt='Profile'
              className='rounded-circle' // Apply the rounded class for circular shape
              style={{width: '45px', height: '45px', objectFit: 'cover'}}
            />
          ) : (
            <Avatar
              size='50'
              round='25px'
              className='symbol symbol-45px me-2'
              style={{fontSize: '18px'}}
              maxInitials={2}
              name={user?.name}
            />
          )}
        </Link>
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <Link
          to={`/users/view/${user?.id}`}
          className='text-gray-900 fw-bold text-hover-primary fs-6'
        >
          {user?.name}
        </Link>
        {user?.userType?.name && (
          <span
            className={`fw-semibold d-inline-block fs-7  ${
              badgeBorderClasses[user.userType.name] || 'border border-secondary text-secondary'
            } text-truncate`}
          >
            {user.userType.name}
          </span>
        )}
      </div>
    </div>
  )
}
