/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {News} from '../../core/_models'
import Avatar from 'react-avatar'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import '../../../buy.css'
import {Link} from 'react-router-dom'
type Props = {
  buy: any
  columnAccessor: string
}

const EmployeeInfoCell: FC<Props> = ({buy, columnAccessor}) => {
  return (
    <>
      {columnAccessor === 'status' && (
        <div className='d-flex align-items-center'>
          <h6 className={`status-${buy?.status}`}>{buy?.status}</h6>
        </div>
      )}
      {columnAccessor === 'Start Date' && (
        <div className='d-flex align-items-center'>
          <h6 className={`status-${buy.created_date}`}>{buy.created_date}</h6>
        </div>
      )}
      {columnAccessor === 'End Date' && (
        <div className='d-flex align-items-center'>
          <h6 className={`status-${buy.valid_date}`}>{buy.valid_date}</h6>
        </div>
      )}
    </>
  )
}

const InfoCell: FC<any> = ({data, user}) => {
  const isUser = data === 'user'

  // Determine values based on `data` type
  const id = isUser ? user?.id : user?.primaryId
  const image = isUser ? user?.profile_img : user?.ProductMaster.image
  const name = isUser ? user?.name : user?.ProductMaster.name
  const category = isUser ? user?.mobile.toString() : user?.buysell_category.name
  const navigate = isUser ? "users" : data === "buy" ? "buyer-request" : "seller-request"

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-2'>
 
          <Link to={`/${navigate}/view/${id}`} className='text-gray-900 fw-bold text-hover-primary fs-6'>
            {image ? (
              <img
                src={image}
                alt='Profile'
                className='rounded-circle'
                style={{width: '45px', height: '45px', objectFit: 'cover'}}
              />
            ) : (
              <Avatar
                size='45'
                round='25px'
                maxInitials={2}
                name={name}
                style={{fontSize: '18px'}}
              />
            )}
          </Link>

      </div>
      <div className='d-flex justify-content-start flex-column'>

          <Link to={`/${navigate}/view/${id}`} className='text-gray-900 fw-bold text-hover-primary fs-6'>
            {name}
          </Link>
        <span className='fw-semibold d-inline-block fs-7'>{category}</span>
      </div>
    </div>
  )
}

export {EmployeeInfoCell, InfoCell}
