import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import {getUserGraph} from './_requests'

type Props = {
  className: string
  totalActiveUsers: number
}

const PaymentReceiveGraph: React.FC<Props> = ({className, totalActiveUsers}) => {
  //
  const [data, setData] = useState<number[]>([])
  const [categories, setCategories] = useState<string[]>([])
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null])
  const [startDate, endDate] = dateRange

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }
    //
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, categories, data))
    if (chart) {
      chart.render()
    }
    return chart
  }

  useEffect(() => {
    const chart = refreshMode()
    //
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, categories, data])

  const buttons = [
    {id: 1, name: 'Day'},
    {id: 2, name: 'Week'},
    {id: 3, name: 'Month'},
    {id: 4, name: 'Year'},
    {id: 5, name: 'Lifetime'},
    {id: 6, name: 'Custom Date Range'},
  ]

  // State to track the active button ID
  const [activeButton, setActiveButton] = useState<number>(1) // Default to 'Today'

  const handleButtonClick = (id: number) => {
    setActiveButton(id)
  }

  useEffect(() => {
    // Get current date
    const currentDate = new Date()

    // Get the first and last day of the current month
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
    // Set the initial date range for DatePicker
    setDateRange([firstDayOfMonth, lastDayOfMonth])

    const data = {
      customDateRange: null,
      rangeType: activeButton,
    }
    console.log('Custom date range for current month:', data)
    if (activeButton != 6) {
      getUserGraph(data).then((res) => {
        console.log('getUserGraph-res', res)
        setData(res.data.userCounts)
        setCategories(res.data.categories)
      })
    }
  }, [activeButton])

  const filterData = (event: any) => {
    // Use the dateRange state to construct the custom_date string
    if (event[0] && event[1]) {
      const formatDate = (date: any) => {
        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      }

      const custom_date = `${formatDate(event[0])} - ${formatDate(event[1])}`

      console.log('Custom date range for filterData:', custom_date)

      const data = {
        customDateRange: custom_date,
        rangeType: activeButton,
      }

      getUserGraph(data)
        .then((res: any) => {
          setData(res.data.userCounts)
          setCategories(res.data.categories)
        })
        .catch((error) => {
          console.error('Error fetching user graph data:', error)
        })
    } else {
      console.log('Date range is not set.')
    }
  }

  interface DateRange {
    startDate: Date
    endDate: Date
  }
  const [dateState, setDateState] = useState<DateRange>({
    startDate: new Date(),
    endDate: new Date(),
  })
  return (
    <div>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>User Count</span>
          </h3>

          {/* begin::Toolbar */}
          <div className='card-toolbar' data-kt-buttons='true'>
            {buttons.map((button) => {
              console.log(activeButton === button.id) // Debugging if the button is active
              return (
                <a
                  key={button.id}
                  className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
                    activeButton === button.id ? 'active' : ''
                  }`}
                  onClick={() => handleButtonClick(button.id)}
                >
                  {button.name}
                </a>
              )
            })}
          </div>

          {/* end::Toolbar */}
        </div>
        <div className='d-flex justify-content-between align-items-center me-5'>
          {/* Total Active Users */}
          <div className='ms-5 ps-5'>
            <span className='card-label fw-bold fs-1 mb-1'>{totalActiveUsers}</span>
          </div>

          {/* Date Picker Section */}
          {activeButton == 6 && (
            <div className='ms-auto'>
              <div className='input-group position-relative align-items-center'>
                <button
                  type='button'
                  className='btn btn-icon btn-light position-absolute p-1'
                  style={{left: '-30px', zIndex: 1}}
                >
                  <i className='fas fa-calendar-alt'></i>
                </button>
                <DatePicker
                  className='form-control form-control-lg form-control-solid ps-5'
                  id='Daterange'
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(event: any) => {
                    setDateRange(event)
                    filterData(event)
                  }}
                  isClearable={true}
                  dateFormat='dd/MM/yyyy'
                  placeholderText='Select date range'
                  aria-label='Select date range'
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode='select'
                />
              </div>
            </div>
          )}
        </div>

        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body'>
          {/* begin::Chart */}
          <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '150px'}}></div>
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export {PaymentReceiveGraph}

function getChartOptions(height: number, categories: string[], data: number[]): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-warning')
  const lightColor = getCSSVariableValue('--kt-warning-light')

  return {
    series: [
      {
        name: 'User Count',
        data: data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val
          // return '' + val + ' count '
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
