// @ts-nocheck
import {Column} from 'react-table'
import {
  EmployeeInfoCell,
  EmployeePlanStartCell,
  EmployeePlanEndCell,
  EmployeePlanRegCell,
  EmployeeSubscriptionInfoCell,
  EmployeePlanCell,
  InfoCell,
} from './EmployeeInfoCell'

import {EmployeeActionsCell} from './EmployeeActionsCell'
import {EmployeeCustomHeader} from './EmployeeCustomHeader'
import {Employee} from '../../core/_models'

const EmployeeColumns: ReadonlyArray<Column<Employee>> = [
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='UID' className='min-w-75px' />
    ),
    accessor: 'id',
    Cell: ({row}) => {
      const data = row.original
      return <div>{data.primaryId}</div>
    },
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='User Name' className='min-w-125px' />
    ),
    accessor: 'name',
    Cell: ({...props}) => <InfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Register Date' className='min-w-125px' />
    ),
    accessor: 'created',
    Cell: ({...props}) => <EmployeePlanRegCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Mobile' className='min-w-125px' />
    ),
    accessor: 'mobile',
    Cell: ({row}) => {
      const data = row.original
      return (
        <div className='d-flex justify-content-start flex-column'>
          <div>{data.mobile}</div>
          <div className='text-muted fw-semibold d-block fs-7'>{data.email || ''}</div>
        </div>
      )
    },
  },

  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Location' className='min-w-175px' />
    ),
    accessor: 'town',
    Cell: ({row}) => {
      const data = row.original

      // Construct tooltip parts array dynamically, only including non-empty fields
      const tooltipParts = [
        data.town ? <div key='location'>{data.location}</div> : null,
        data.town ? <div key='town'>{data.town}</div> : null,
        data.district ? <div key='district'>{data.district}</div> : null,
        data.state ? <div key='state'>{data.state}</div> : null,
      ].filter(Boolean) // Filter out empty values

      return (
        <div className='d-flex justify-content-start flex-column custom-tooltip'>
          <div>{data.town || ''}</div>
          <div className='text-muted fw-semibold d-block fs-7'>{data.district || ''}</div>

          {/* Show the tooltip content only if there's data */}
          {tooltipParts.length > 0 && (
            <div className='tooltip-content'>
              {tooltipParts} {/* Each part is rendered in a separate div */}
            </div>
          )}
        </div>
      )
    },
  },

  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Plans' className='min-w-200px' />
    ),
    accessor: 'district',
    Cell: ({...props}) => <EmployeeSubscriptionInfoCell user={props.data[props.row.index]} />,
  },

]

export {EmployeeColumns}
