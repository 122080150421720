// @ts-nocheck
import {Column} from 'react-table'
import {
  ProductStartInfoCell,
  ProductEndInfoCell,
  ProductStatusCell,
  SubscriptionDateInfoCell,
  InfoCell,
} from './ProductInfoCell'
import {Link} from 'react-router-dom'
import {ProductCustomHeader} from './ProductCustomHeader'
import {Product} from '../../core/_models'
import {ProductActionsCell} from './ProductActionsCell'
import {dateFormat, timeFormat} from '../../../../../auth'
const EmployeeColumns: ReadonlyArray<Column<Product>> = [
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='UserId' className='min-w-75px' />
    ),
    accessor: 'user_id',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Subscription Id' className='min-w-150px' />
    ),
    accessor: 'subscription_id',
    Cell: ({row}) => {
      const data = row.original
      return (
        <div className='d-flex justify-content-start flex-column'>
          <Link
            to={`/subscription/view/${data?.id}`}
            className='text-gray-900 fw-bold text-hover-primary fs-6'
          >
            {data?.subscription_id}
          </Link>
          <span className={`fw-semibold d-inline-block fs-7  `}>
            {dateFormat(data?.created_at)},{timeFormat(data?.created_at)}
          </span>
        </div>
      )
    },
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'user.name',
    Cell: ({...props}) => <InfoCell user={props.data[props.row.index].user} />,
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Mobile' className='min-w-125px' />
    ),
    accessor: 'user.mobile',
    Cell: ({row}) => {
      const data = row.original
      return (
        <div className='d-flex justify-content-start flex-column'>
          <div>{data.user.mobile}</div>
          <div className='text-muted fw-semibold d-block fs-7'>{data.user.email || ''}</div>
        </div>
      )
    },
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Plan' className='min-w-125px' />
    ),
    accessor: (row) => row?.plan.name,
    id: 'planName', 
    Cell: ({ row }) => {
      const data = row.original;
      const planName = data?.plan?.name || 'Unknown Plan';
      const isExpired = data?.user_plan_detail?.isExpired;
      const statusText = isExpired ? "Expired" : "Active";
      const statusClass = isExpired ? "text-danger" : "text-success";
   
      return (
         <div className='d-flex justify-content-start flex-column'>
            <div>{planName}</div>
            <span className={`fw-semibold d-inline-block fs-7 ${statusClass}`}>
               {statusText}
            </span>
         </div>
      );
   }
   
    
  },

  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Plan Days' className='min-w-125px' />
    ),
    accessor: (row) => row?.plan.days,
    id: 'planDays', // Added id for this column
  },

  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Receipt' className='min-w-125px' />
    ),
    accessor: 'razorpay_payment_id',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Plan Start' className='min-w-125px' />
    ),
    accessor: 'user_plan_detail.startDate',
    Cell: ({...props}) => <ProductStartInfoCell product={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Plan End' className='min-w-125px' />
    ),
    accessor: 'user_plan_detail.endDate',
    Cell: ({...props}) => <ProductEndInfoCell product={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'payment_status',
    Cell: ({row}) => {
      const data = row.original

      // Define badge colors based on payment status
      const getStatusBadge = (status) => {
        switch (status) {
          case 'captured':
            return 'badge badge-success'
          case 'failed':
            return 'badge badge-danger'
          case 'free plan':
            return 'badge badge-info'
          default:
            return 'badge badge-secondary'
        }
      }

      // Determine the label and badge based on payment status
      const statusLabel =
        data.payment_status === 'free plan' ? 'created' : data.payment_status || 'created'
      const badgeClass = getStatusBadge(statusLabel)

      return <div className={badgeClass}>{statusLabel}</div>
    },
  },
  // {
  //   Header: (props) => (
  //     <ProductCustomHeader tableProps={props} title='Subscription Date' className='min-w-175px' />
  //   ),
  //   accessor: 'created_at',
  //   Cell: ({...props}) => <SubscriptionDateInfoCell product={props.data[props.row.index]} />,
  // },
]

export {EmployeeColumns}
