import React from 'react';
import { dateFormat, timeFormat } from '../../../app/modules/auth/core/AuthHelpers';

interface DateTimeFormatProps {
  date: any;
}

export const DateTimeFormat: React.FC<DateTimeFormatProps> = ({ date }) => {
//   const dateFormat = (date: any) => new Date(date).toLocaleDateString(); // Sample date formatting
//   const timeFormat = (date: any) => new Date(date).toLocaleTimeString(); // Sample time formatting

  return (
    <div className='d-flex justify-content-start flex-column'>
      <div>{dateFormat(date)}</div>
      <div className='text-muted fw-semibold text-muted d-block fs-7'>
        {timeFormat(date)}
      </div>
    </div>
  );
};
