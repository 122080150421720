import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {EmployeeListWrapper} from './buy-list/EmployeeList'

import {View} from './buy-form/View'

const employeesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Buy Management',
    path: '/buyer-request',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BuyPage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Buyer Request list</PageTitle>
              <EmployeeListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />

      <Route
        path='/view/:id'
        element={
          <>
         <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
         <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
            <PageTitle breadcrumbs={employeesBreadcrumbs}>Buyer Request View</PageTitle>
            <View />
          </>
        }
      />
    </Routes>
  )
}

export default BuyPage
